import { ArrowBack } from "@material-ui/icons";
import { translate } from "@ollie-sports/i18n";
import React from "react";
import { useHistory } from "react-router-dom";

export function BackButton() {
  const history = useHistory();
  return (
    <div
      className="flex items-center text-blue-500 text-lg gap-2 cursor-pointer"
      onClick={() => {
        history.goBack();
      }}
    >
      <ArrowBack /> <div>{translate.common.Back}</div>
    </div>
  );
}
